const apiURL =
"https://innovationhealthservices.blob.core.windows.net/healthservices/DE_NPI_DisplayNames_TractLevel_2021.json"
// "https://innovationhealthservices.blob.core.windows.net/healthservices/DE_NPI_TractLevel_2021.json";


const binRange = [    
      {bin: 'No Providers', color: '#8c510a'},
      {bin: '0.0 to 0.6 per provider', color: '#bf812d'},
      {bin: '0.6 to 1.4 per provider', color: '#dfc27d'},
      {bin: '1.4 to 2.2 per provider', color: '#f6e8c3'},
      {bin: '2.2 to 4.5 per provider', color: '#f5f5f5'},
      {bin: '4.5 to 8.4 per provider', color: '#c7eae5'},
      {bin: '8.4 to 16.0 per provider', color: '#80cdc1'},
      {bin: '16.0 to 24.8 per provider', color: '#35978f'},
      {bin: '24.8 to 141.0 per provider', color: '#01665e'},    
]


const binRangeForLabel = [    
      {bin: 'No Providers', color: '#8c510a'},
      {bin: '0 to 6 Provider', color: '#bf812d'},
      {bin: '6 to 14 Provider', color: '#dfc27d'},
      {bin: '14 to 22 Provider', color: '#f6e8c3'},
      {bin: '22 to 45 Provider', color: '#f5f5f5'},
      {bin: '45 to 84 Provider', color: '#c7eae5'},
      {bin: '84 to 160 Provider', color: '#80cdc1'},
      {bin: '160 to 248 Provider', color: '#35978f'},
      {bin: '248 to 1410 Provider', color: '#01665e'},    
]

// const binRange = [    
//       {bin: 'No Providers', color: '#8c510a'},
//       {bin: '0.0 to 0.9 per provider', color: '#bf812d'},
//       {bin: '0.9 to 1.9 per provider', color: '#dfc27d'},
//       {bin: '1.9 to 3.4 per provider', color: '#f6e8c3'},
//       {bin: '3.4 to 6.0 per provider', color: '#f5f5f5'},
//       {bin: '6.0 to 8.7 per provider', color: '#c7eae5'},
//       {bin: '8.7 to 14.1 per provider', color: '#80cdc1'},
//       {bin: '14.1 to 23.0 per provider', color: '#35978f'},
//       {bin: '23.0 to 145.0 per provider', color: '#01665e'},    
// ]

export { apiURL, binRange, binRangeForLabel };
