import React, {useEffect, useState} from "react";
import "./App.css";
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import {MapContainer} from 'react-leaflet';
import {binRange, apiURL, binRangeForLabel} from "./config";

const apiKey = process.env.REACT_APP_LEAFLET_API_KEY;

function getColorForBin(bin) {
    const foundCategory = binRange.find((category) => category.bin === bin); 
    return foundCategory ? foundCategory.color : "#ffffff";        
} 

function App () {
    const [data, setData] = useState(null);  
    // const [selectedOption, setSelectedOption] = useState("cpp_ratio");
    // const variable = "cpp_ratio";


/**
 * The function fetchData is an asynchronous function that fetches data from an API, checks if the
 * response is successful, and sets the fetched data using the setData function.
*/
  async function fetchData() {
    try {
      const response = await fetch(apiURL);

      if (response.ok) {
        const jsonData = await response.json();
        setData(jsonData);
      } else {
        console.error("Failed to fetch data:", response.statusText);
        
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

 /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it is
used to fetch data from an API when the component mounts. */
useEffect(() => {
    fetchData();
  }, []);

/* The `useEffect` hook in the code is used to create and initialize a Leaflet map when the `data`
state variable is updated. */
useEffect(() => {
    if (data) {
      const mapContainer = document.getElementById("map");

      const map = L.map(mapContainer).setView([39.1, -74.9], 9);
      L.tileLayer(
        `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${apiKey}`,
        {
          maxZoom: 32,
          id: "mapbox/streets-v11",
        }
      ).addTo(map);

      L.geoJSON(data, {
        style: function (feature) {
            const cppRange = feature.properties.cpp_ratio_bin;
            const colorValue = getColorForBin(cppRange);
            console.log(cppRange + colorValue);
            return {
                fillColor: colorValue,
                fillOpacity: 1,
                color: "#000000",
                weight: 0.3,
            };
        },
        onEachFeature: function (feature, layer) {
        // Some of the features have no cpp_ratio field if there are no providers in the census tract.
        //It shows undefined in the popup, to prevent this, display 0 if cpp_ratio is undefined.
          const cppRatio = feature.properties.cpp_ratio !== undefined ? (feature.properties.cpp_ratio * 10).toFixed(0) : 0;

            layer.bindPopup(
                "Census Tract: " +
                  feature.properties.NAMELSAD.toString() +
                  "<br>" +
                  "Child Per Provider Ratio: " +
                  cppRatio +
                  "<br>" +
                  "Total Providers: " +
                  feature.properties['Total Providers'].toFixed(0) +
                  "<br>" +
                  "Population of Children with Cognitive Difficulty: " +
                  feature.properties['Patients with CD (5 to 17)'].toFixed(0) 
              );
        }
      }).addTo(map);

      return () => map.remove();
    }

}, [data]);

const formattedLabelEntries = binRangeForLabel.map((category, index) => (
    <li className="label-entry" key={index}>
      <span style={{ backgroundColor: category.color }} className="cat-color"></span>{" "}
      <span className="bin-range">
        {category.bin}
      </span>{" "}
    </li>
  ));


return (
     <div id="all-container">
      <MapContainer 
        center={[39.1, -75.300]} 
        zoom={9} 
        scrollWheelZoom={true}    
        style={{ height: "100%", width: "100%" }}
      >
      <div id="map"> 
      
        <div className="label-container"> 
            <h2>Child with Cognitive Difficulty To Provider Ratio per 10 Kids</h2>
            <ul className="label-entries">{formattedLabelEntries}</ul>          
        </div>
      </div>   
      <div className="logo-container">
        <img src="tilogo.png" alt="Tech Impact logo" className="tilogo" />
      </div>
      </MapContainer> 
    </div>  
  );

}

export default App;